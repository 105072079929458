import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { layout: 'default'},
    component: () => import(/* webpackChunkName: "home" */ '../views/Home')
  },
  {
    path: '/dashboard/:site?',
    name: 'Dashboard',
    meta: { layout: 'default'},
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue')
  },
  {
    path: '/dashboard/:site?/incident/:id?',
    name: 'Dashboard Incident',
    meta: { layout: 'default'},
    component: () => import(/* webpackChunkName: "about" */ '../views/Incident.vue')
  },
  {
    path: '/dashboard/:site?/maintenance/:id?',
    name: 'Dashboard Maintenance',
    meta: { layout: 'default'},
    component: () => import(/* webpackChunkName: "about" */ '../views/Maintenance.vue')
  },
  {
    path: '/subscribe/confirmation/:subID?',
    name: 'Confirm Subscription',
    meta: { layout: 'default'},
    component: () => import(/* webpackChunkName: "Config" */ '../views/SubConfirm.vue')
  },
  {
    path: '/history/:site?',
    name: 'History',
    meta: { layout: 'default'},
    component: () => import(/* webpackChunkName: "history" */ '../views/History.vue')
  },
  {
    path: '/health',
    redirect: to => {
      return {
        path: `/history/${to.query.site}/incident/${to.query.si}`,
        query: null
      }
    }
  },
  {
    path: '/history/:site?/incident/:id?',
    name: 'History Incident',
    meta: { layout: 'default'},
    component: () => import(/* webpackChunkName: "history" */ '../views/Incident.vue')
  },
  {
    path: '/history/:site?/maintenance/:id?',
    name: 'History Maintenance',
    meta: { layout: 'default'},
    component: () => import(/* webpackChunkName: "history" */ '../views/Maintenance.vue')
  },
  {
    path: '/current/:site?',
    name: 'Current Status',
    meta: { layout: 'default'},
    component: () => import(/* webpackChunkName: "history" */ '../views/CurrentStatus.vue')
  },
  {
    path: '/current/:site?/incident/:id?',
    name: 'Current Incident',
    meta: { layout: 'default'},
    component: () => import(/* webpackChunkName: "history" */ '../views/Incident.vue')
  },
  {
    path: '/current/:site?/maintenance/:id?',
    name: 'Current Maintenance',
    meta: { layout: 'default'},
    component: () => import(/* webpackChunkName: "history" */ '../views/Maintenance.vue')
  },
  {
    path: '/futureMaintenance/:site?',
    name: 'Future Maintenance',
    meta: { layout: 'default'},
    component: () => import(/* webpackChunkName: "history" */ '../views/FutureMaintenance.vue')
  },
  {
    path: '/futureMaintenance/:site?/maintenance/:id?',
    name: 'Planned Maintenance',
    meta: { layout: 'default'},
    component: () => import(/* webpackChunkName: "history" */ '../views/Maintenance.vue')
  },
  {
    path: '/api',
    name: 'API',
    meta: { layout: 'default'},
    component: () => import(/* webpackChunkName: "api" */ '../views/Api.vue')
  },

  {
    path: '*',
    name: 'Not Found',
    meta: { layout: 'default'},
    component: () => import(/* webpackChunkName: "api" */ '../views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
