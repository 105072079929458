export default Object.freeze({

    STATUS: {
      pending: 'Pending',
      advance_notice: 'Advance Notice',
      new: 'New',
      in_progress: 'In Progress',
      change_notice: 'Change Notice',
      validation: 'Validation',
      resolved: 'Resolved',
      completed: 'Completed',
      cancelled: 'Cancelled',
      prca_update: 'PRCA-UPDATE',
      prca: 'PRCA',
      rca: 'RCA'
    },
    TYPE: {
      downtime: 'Downtime',
      degradation: 'Degradation',
      third_party: '3rd Party',
      suspected: 'Suspected'
    },
    headers: [
      {
        text: 'Type',
        align: 'center',
        sortable: false,
        value: 'incident_type',
        width: '50px'
      },
      {
        text: 'Support Case',
        align: 'center',
        sortable: false,
        value: 'support_case',
        width: '50px'
      },
      {
        text: 'Impacted Services',
        value: 'service_impacted',
        align: 'start',
        width: '150px'
      },
      {
        text: 'Description',
        value: 'impact',
        align: 'start',
        width: '300px'
      },
      {
        text: 'Started On',
        value: 'start_date',
        align: 'start',
        width: '150px'
      }
    ],

  maintenance_headers: [
    {
      text: 'Support Case',
      align: 'center',
      sortable: false,
      value: 'support_case',
      width: '50px'
    },
    {
      text: 'Impacted Services',
      value: 'service_impacted',
      align: 'start',
      width: '150px'
    },
    {
      text: 'Description',
      value: 'impact',
      align: 'start',
      width: '300px'
    },
    {
      text: 'Start Date',
      value: 'start_date',
      align: 'start',
      width: '150px'
    },
    {
      text: 'End Date',
      value: 'end_date',
      align: 'start',
      width: '150px'
    }
  ],
    footerProps: {
      showFirstLastPage: true,
      firstIcon: 'mdi-chevron-double-left',
      lastIcon: 'mdi-chevron-double-right',
      prevIcon: 'mdi-chevron-left',
      nextIcon: 'mdi-chevron-right',
      itemsPerPageOptions: [10, 20, 50],
      itemsPerPageText: 'Show items:',
      showCurrentPage: true
    },
    timeZones: [
      'America/New_York',
      'America/Chicago',
      'America/Los_Angeles',
      'Europe/London',
      'Europe/Berlin',
      'Australia/Sydney',
      'Asia/Tokyo',
      'Asia/Jerusalem',
      'Asia/Hong_Kong',
      'Etc/UTC'
    ],

    RecoveryColorClass: {
      'Technical investigation is ongoing': 'red--text',
      'The issue has been identified': 'amber--text',
      'The fix has been implemented, pending full system recovery': 'green--text',
      'Full system recovery': 'blue--text',
    },
    estTimezone: 'America/New_York',
    userTimeZone: 'usertimezone',

  }
);