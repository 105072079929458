import Vue from 'vue'
import Vuex from 'vuex'
import Site from './site'
import Tools from './tools'
import Subscribe from './subscribe'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Site,
    Subscribe,
    Tools
  },
  plugins: [createPersistedState({
    storage: window.localStorage,
  })]
})




