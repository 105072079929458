<template>
  <component :is="layout">
    <v-overlay opacity="1" color="background" v-if="loading">
      <p class="body-1 mb-4 text--primary">Getting your data and configuring the platform...</p>
      <v-progress-linear  :indeterminate="true"></v-progress-linear>
    </v-overlay>
    <router-view></router-view>
    <message-modal
        ref="messageModal"
        :message="popup.message"
        :dialog="popup.active"
        @close="hidePopup"/>
  </component>
</template>

<script>

import { mapGetters, mapActions, mapMutations } from 'vuex'
import messageModal from '@/components/popup-components/message-modal'
export default {
  name: 'App',
  components: {
    messageModal,
    default: () => import('@/layouts/default'),
  },
  async created () {
    this.setLoading(true)
    this.setTimeZone()
    this.getThemeData()
    await this.fetchServices()
    if (this.siteId) await this.getData(this.siteId)
    this.setLoading(false)
    window.onbeforeunload = () => this.updateFilterDates([]);

  },

  computed: {
    ...mapGetters('Site', ['getTimeZone']),
    ...mapGetters('Tools', ['getPopUp', 'getLoading']),
    siteId () {
      return this.$route.params.site || null
    },
    timezone(){
      return this.getTimeZone
    },
    layout(){
      return this.$route.meta.layout
    },
    popup(){
      return this.getPopUp
    },
    loading(){
      return this.getLoading
    }
  },
  methods:{
    ...mapActions('Tools', ['hidePopup']),
    ...mapActions('Site', ['getData', 'fetchServices', 'updateFilterDates']),
    ...mapMutations('Tools', ['setLoading']),
    setTimeZone () {
      if (!this.timezone) {
        this.$store.dispatch('Site/updateTimezone', this.$constants.estTimezone);
      }
    },
    getThemeData(){
      const theme = localStorage.getItem("dark_theme");
      if (theme) {
        this.$vuetify.theme.dark = theme === "true";
      } else if (
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        this.$vuetify.theme.dark = true;
        localStorage.setItem(
            "dark_theme",
            this.$vuetify.theme.dark.toString()
        );
      }
    },

  }
};
</script>
