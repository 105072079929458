<template>
  <v-dialog
      v-model="dialog"
      max-width="450"
      @click:outside="dialog = false"
  >
    <v-card>
      <div class="pa-3 d-flex justify-space-between align-center">
        <p> {{ message }} </p>
      </div>
      <v-btn
          class="ma-1"
          color="grey"
          plain
          @click="close"
      >
        Close
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'message-modal',
  props: {
    message: {
      type: String,
      default: ''
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },

  methods:{
    close(){
      this.$emit('close')
    }
  }

}
</script>

<style scoped>

</style>
