import Vue from 'vue'
import updateServiceSatus from '@/helpers/updateServiceSatus'

const namespaced = true

const state = () => ({
  siteID: '',
  timezone: null,
  services: null,
  updatedServices: [],
  lob: null,
  name: null,
  pastStatus: [],
  futureMaintenance: [],
  realTimeIncidents: [],
  ongoingMaintenance: [],
  filterDates: []
})

const getters = {
  getTimeZone: state => state.timezone,
  getPastStatus: state => state.pastStatus,
  getFutureMaintenance: state => state.futureMaintenance,
  getRealTimeIncidents: state => state.realTimeIncidents,
  getOngoingMaintenance: state => state.ongoingMaintenance,
  getFilterDates: state => state.filterDates,
  getLob: state => state.lob,
  getName: state => state.name,
  getUpdatedServices: state => state.updatedServices
}

const mutations = {

  setTimezone (state, timezone) {
    state.timezone = timezone
  },
  setServices (state, services) {
    state.services = [...services]
  },
  setPastStatus (state, pastStatus) {
    state.pastStatus = [...pastStatus]
  },
  setFutureMaintenance (state, futureMaintenance) {
    state.futureMaintenance = [...futureMaintenance]
  },
  setRealTimeIncidents (state, realTimeIncidents) {
    state.realTimeIncidents = [...realTimeIncidents]
  },
  setOngoingMaintenance (state, ongoingMaintenance) {
    state.ongoingMaintenance = [...ongoingMaintenance]
  },
  setLob (state, lob) {
    state.lob = lob
  },
  setName (state, name) {
    state.name = name
  },
  setUpdatedServices (state, updatedServices) {
    state.updatedServices = [...updatedServices]
  },
  setFilterDates (state, filterDates) {
    state.filterDates = [...filterDates]
  }
}

const actions = {

  updateFilterDates ({ commit }, dates) {
    commit('setFilterDates', dates)
  },
  updateTimezone ({ commit }, tz) {
    commit('setTimezone', tz)
  },
  async getData ({ commit, state }, id) {
    try{
      const resp =  await this._vm.$axios.get(`/data/${id}?offset=${0}`)
      const {lob, name} = resp.data.data.site_data
      commit('setLob', lob)
      commit('setName', name)
      const {incidents, maintenance, legacy} = resp.data.data.records
      const {pastStatus, pastMaintenance, realtimeIncidents, ongoingMaintenance, futureMaintenance} =
        siteDataDestructionFunction(incidentsDestructionFunction(incidents), incidentsDestructionFunction(maintenance), incidentsDestructionFunction(legacy))
      commit('setRealTimeIncidents', realtimeIncidents)
      commit('setOngoingMaintenance', ongoingMaintenance)
      commit('setPastStatus', [...pastStatus, ...pastMaintenance])
      commit('setFutureMaintenance', futureMaintenance)
      commit('setUpdatedServices', updateServiceSatus(state.services, realtimeIncidents, ongoingMaintenance))
    } catch (err){
      console.error(err)
      throw err
    }
  },
  async fetchIncidentData(ctx, id){
    try {
      const resp = await this._vm.$axios.get(`/data/incident/${id}`)
      const data = incidentsDestructionFunction([resp.data.data])
      return data[0]
    } catch (err){
      console.error(err)
      throw err
    }
  },

  async fetchMaintenanceData(ctx, id){
    try {
      const resp = await this._vm.$axios.get(`/data/maintenance/${id}`)
      const data = incidentsDestructionFunction([resp.data.data])
      return data[0]
    } catch (err){
      console.error(err)
      return false
    }
  },

  async fetchServices ({commit}) {
    try {
      const resp = await this._vm.$axios.get('/settings/services')
      const { value } = resp.data.data
      const result = value.split(',')
      commit('setServices', result.map((item) => { return { name: item, status: 'Ok', link: [] }}))
    }catch (err){
      console.error(err)
      throw err
    }

  },

  async downloadRCA({ dispatch }, payload){
    try{
      const resp  = await this._vm.$axios.post(`/data/rca/download`, payload)
      if (showPopup(resp.data.data))
        return true;
      else{
        await dispatch('Tools/showPopup', "Pop-up Blocker is enabled! Please add this site to your exception list.", { root: true } );
        return false;
      }

    }catch (err){
      console.error(err)
      return false
    }
  }
}

function showPopup(url) {
  const windowReference = window.open();
  if ( windowReference !== null){
    windowReference.focus();
    windowReference.location = url;
    return true
  }
  return false;
}

function incidentsDestructionFunction (data = [])  {
  if(!data.length) return []
  return data.filter(x=> x.status !== 'deleted').map((incident) => {
    return Object.fromEntries(
      Object.entries(incident)
        .map(interruptionDataDestruction)
    )
  })
}

const incidentTypeDictionary = {
  'downtime': 'Downtime',
  'suspected': 'Suspected',
  'thirdparty': '3rd Party',
  'degradation': 'Degradation',
}

const serviceImpactedDictionary = {
  'agent workspace': 'Agent Workspace',
  'login': 'Login',
  'visitor experience': 'Visitor Experience',
  'bots and automation': 'Bots and Automation',
  'campaign management and admin' :'Campaign Management and Admin',
  'integrations and apis':'Integrations and APIs',
  'reporting and bi dashboard' : 'Reporting and BI Dashboard',
}

const interruptionDataDestruction = ([key, val]) => {
  let value = val || ''
  switch (key) {
    case 'incidentType':
      return ['incident_type', incidentTypeDictionary[val] || val]
    case 'service_impacted':
      return [key, serviceImpactedDictionary[val] || val]
    case 'zoom':
      return ['bridge', val]
    default:
      return [key, value]
  }
}

function siteDataDestructionFunction (incidents, maintenance, legacy) {
  const pastStatus = [...legacy]
  const realtimeIncidents = []
  const futureMaintenance = []
  const pastMaintenance = []
  const ongoingMaintenance = []

  incidents.forEach((item) => {
    const onGoingStatuses = [Vue.prototype.$constants.STATUS.new, Vue.prototype.$constants.STATUS.in_progress]
    onGoingStatuses.includes(item.status) ? realtimeIncidents.push(item) : pastStatus.push(item)
  })

  maintenance.forEach((item) => {
    switch(item.status){
      case Vue.prototype.$constants.STATUS.advance_notice:
        return futureMaintenance.push(item)
      case Vue.prototype.$constants.STATUS.in_progress:
      case Vue.prototype.$constants.STATUS.change_notice:
      case Vue.prototype.$constants.STATUS.validation:
        return ongoingMaintenance.push(item)
      case Vue.prototype.$constants.STATUS.completed:
      case Vue.prototype.$constants.STATUS.cancelled:
        return pastMaintenance.push(item)
    }
  })

  return { realtimeIncidents, pastStatus, futureMaintenance, pastMaintenance, ongoingMaintenance}
}

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions
}
