const namespaced = true

const state = () => ({
    popup: {active: false, message: "" },
    loading: false,
    version: "",
})

const getters = {
    getPopUp: (state) => state.popup,
    getLoading: (state) => state.loading,
    getVersion: (state) => state.version,
}

const mutations = {
    setPopup(state, popup) {
        state.popup = popup;
    },
    setLoading(state, loading) {
        state.loading = loading;
    },
    setVersion(state, version) {
        state.version = version;
    },
}

const actions = {
    showPopup({ commit }, message) {
        commit("setPopup", {
            active: true,
            message: message
        });
    },
    hidePopup({ commit }) {
        commit("setPopup", {
            active: false,
            message: ''
        })
    },
    async fetchVersion({ commit }) {
        try {
            const resp = await this._vm.$axios.get(`/settings/version`);
            commit("setVersion", resp.data.data);
        } catch (err) {
            console.log(err);
        }
    },

}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
