import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
    dark:  true,
    options: {
      customProperties: true
    },
    themes: {
      dark: {
        background: '#020627',
        primary: '#091043',
        periwinkle: '#3955A7',
        secondary: '#A7ACBE',
        lightGrey: '#DBDCE1',
        accent: '#FF6900',
        error: '#FF5252',
        errorLight: '#F29295',
        info: '#2DBAE6',
        success: '#4CAF50',
        lime: '#A0C125',
        golden: '#A68C59',
        warning: '#FFC107',
        navyLight: '#3F4C73',
        darkest: '#2C3249',
        darkRed: '#AC1B20'
      },
      light: {
        background: '#E7EBF5',
        primary: '#ffffff',
        periwinkle: '#3955A7',
        accent: '#FF6900',
        golden: '#A68C59',
      }
    },
  },
});
