import Vue from 'vue'

export default function (services, realtimeIncidents, ongoingMaintenance) {
  realtimeIncidents.forEach((item) => {
    const incidentServices = item.service_impacted.includes(',')
      ? item.service_impacted.split(',')
      : [item.service_impacted]

    incidentServices.forEach((incidentService) => {
      const serviceIndex = services.findIndex(service => service.name === incidentService)
      if (services[serviceIndex].status === 'Ok') {
        services[serviceIndex].status = item.incident_type
        services[serviceIndex].link.push(item.id)
      } else {
        switch (services[serviceIndex].status) {
          case Vue.prototype.$constants.TYPE.degradation:
          case Vue.prototype.$constants.TYPE.third_party:
            if (item.incident_type === Vue.prototype.$constants.TYPE.downtime) {
              realtimeIncidents[serviceIndex].status = item.incident_type
            }
            break
          case Vue.prototype.$constants.TYPE.suspected:
            if ([Vue.prototype.$constants.TYPE.downtime, Vue.prototype.$constants.TYPE.degradation, Vue.prototype.$constants.TYPE.third_party].includes(item.incident_type)) {
              realtimeIncidents[serviceIndex].status = item.incident_type
            }
            break
        }
        services[serviceIndex].link.push(item.id)
      }
    })
  })

  ongoingMaintenance.forEach((item) => {
    const maintenanceServices = item.service_impacted.includes(',')
      ? item.service_impacted.split(',')
      : [item.service_impacted]

    maintenanceServices.forEach((maintenanceService) => {
      const serviceIndex = services.findIndex(service => service.name === maintenanceService)
      if (services[serviceIndex].status === 'Ok') {
        services[serviceIndex].status = 'Maintenance'
      }
        services[serviceIndex].link.push(item.id)
      })
  })
  return services
}
